'use strict'
let currentpw = ''
document.addEventListener('DOMContentLoaded', () => {

    (function alterarMaskedPassword() {
        MaskedPassword.prototype.createContextWrapper = function(passfield) {
            //create the wrapper and add its class
            //it has to be an inline element because we don't know its context
            var wrapper = document.createElement('span')
            wrapper.className = 'input-group'

            var hiddenInputWrapper = document.createElement('span')
            hiddenInputWrapper.className = 'input-group-prepend'

            //insert the wrapper directly before the passfield
            passfield.parentNode.insertBefore(wrapper, passfield)

            wrapper.appendChild(hiddenInputWrapper)
            //then move the passfield inside it
            hiddenInputWrapper.appendChild(passfield)

            //return the wrapper reference
            return wrapper
        }
    })()

    new MaskedPassword(document.getElementById('pwdSenha'), '\u25CF');
    $('#frmSenha').find('span').append($('#frmSenha').find('#showHidePwd'))

    const $pwdSenha = document.getElementById('pwdSenha')

    if ($pwdSenha) {
        const $realfield = document.getElementById('pwdSenha')._realfield
        const $txtUsuario = document.getElementById('txtUsuario')
        $pwdSenha.addEventListener('keypress', SubmitFormLogin)
        $pwdSenha.addEventListener('input', e => currentpw = $realfield.value)
        $txtUsuario.addEventListener('keypress', SubmitFormLogin)
        $realfield.setAttribute('autocomplete', 'one-time-code')
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#browser_compatibility
        // previne o salvamento de credenciais
    }

    if ($('#lblChaveDigitavel').length > 0) {
        inputCopiarTexto('lblChaveDigitavel')
    }
    $('#divInfraCaptcha').has('.h-captcha, .g-recaptcha').addClass('row justify-content-center')
    $('#txtInfraCaptcha').removeClass('infraText')

    $('#showHidePwd').on('mousedown mouseup mouseleave touchstart touchend', function(e) {
        showHidePwd(e.type)
    })

    const erro = document.getElementById('erro').value
    const strTela = document.getElementById('hdnStrTela').value
    const mostrarAvisoRequest = document.getElementById('mostrarAvisoRequest').value

    if (ENV !== 'prod') {
        console.log('Tela: ' + strTela)
        console.log('Ação: ' + document.getElementById('strAcaoSubmit').value)
    }

    //Se realizou cadastro no Eproc e Advogado residente fora da 4a Região, mostra subform com as orientações do TRF4
    if (mostrarAvisoRequest === 'S') {

        const tipoCadastro = document.getElementById('tipoCadastro').value
        const strNomeJustica = document.getElementById('strNomeJustica').value

        if (tipoCadastro === 'jus_postulandi'
            || tipoCadastro === 'conciliador_externo'
            || tipoCadastro === 'representante_legal_pj'
        ) {
            alert(`Seu cadastro foi efetuado com sucesso.\nCompareça à ${strNomeJustica} para ativar seu cadastro.`)
        } else {
            const strLinkOrientacaoCadastroAdvogado = document.getElementById('strLinkOrientacaoCadastroAdvogado').value
            exibirSubFrm(strLinkOrientacaoCadastroAdvogado, 500, 180)
        }
    }

    const foco = document.getElementById('foco').value
    var elmFoco = document.getElementById(foco)
    if (elmFoco && !usuarioJaDigitou) {
        elmFoco.focus()
    }

    var pwdSenha = document.getElementById('pwdSenha')
    if (pwdSenha && !usuarioJaDigitou) {
        pwdSenha.value = ''
    }

    const linkErroCertificadoDigital = document.getElementById('link_erro_certificado_digital').value
    if (erro === 'erro_certificado') {
        exibirSubFrm(linkErroCertificadoDigital, 800, 600)
    }

    const str_msg = document.getElementById('str_msg').value
    if (str_msg) {
        if (strTela !== '2fa_codigo' && strTela !== '2fa_qr') {
            alert(str_msg.replace(/<[^>]+>/g, ''))
        } else {
            exibirErro(str_msg)
        }
    }

})

window.Submit = function(strAcao) {
    document.getElementById('hdnAcao').value = strAcao

    if (validarCadastro()) {
        document.getElementById('frmLogin').submit()
    }
}

window.validarCadastro = function() {
    const strAcao = document.getElementById('hdnAcao').value
    const strTela = document.getElementById('hdnStrTela').value

    if (strTela === 'login') {
        if (infraTrim(document.getElementById('txtUsuario').value) === '') {
            alert('Informe o Usuário.')
            document.getElementById('txtUsuario').focus()
            return false
        }

        if (infraTrim(document.getElementById('pwdSenha').value) === '') {
            alert('Informe a Senha.')
            document.getElementById('pwdSenha').focus()
            return false
        }
    }

    const bolAvisoExclusaoContaApp = document.getElementById('bolAvisoExclusaoContaApp').value
    if (bolAvisoExclusaoContaApp) {
        if (strAcao === '2fa_gerar') {
            alert(
                'ATENÇÃO:\nSe a sua conta no sistema já estiver registrada no aplicativo, certifique-se de excluí-la antes da leitura do QR Code.',
            )
        }
    }

    if (strAcao === '2fa_desativar') {
        if (!confirm('Confirma envio de e-mail com link para desativação da autenticação em 2 fatores?')) {
            return false
        }
    }

    if (strAcao === '2fa_ativar') {
        if (!validarCodigoAcesso()) {
            return false
        }

        if (!validarEmail()) {
            return false
        }
    }

    if (strTela === '2fa_codigo') {
        if ((strAcao !== '2fa_desativar') &&
            (strAcao !== '2fa_dispositivos') &&
            (!validarCodigoAcesso())) {
            return false
        }
    }

    return true
}

function validarEmail() {
    var bolRet = true
    var elemento = document.getElementById('txtEmail')
    elemento.value = elemento.value.trim()

    if (!infraValidarEmail(elemento.value)) {
        exibirErro('Endereço de e-mail inválido.')
        bolRet = false
    } else if (!validarEmailNaoInstitucional(elemento.value)) {
        bolRet = false
    }

    if (!bolRet) {
        elemento.select()
    }

    return bolRet
}

function validarEmailNaoInstitucional(strEmail) {
    const strLinkValidarEmail = document.getElementById('link_validar_email_nao_institucional').value
    let bolEmailValido = true
    $.ajax({
        type: 'post',
        async: false,
        dataType: 'JSON',
        data: {'email': strEmail},
        url: strLinkValidarEmail,
        error: function(xhr, status, error) {
            var errorMessage = xhr.status + ' - ' + xhr.statusText + '. ' + error
            console.log('Erro ao validar email não institucional: ' + errorMessage)
        },
        success: function(retorno) {
            if (retorno != true) {
                alert(retorno)
                bolEmailValido = false
            }
        },
    })
    return bolEmailValido
}

function validarCodigoAcesso() {
    var elemento = document.getElementById('txtAcessoCodigo')
    elemento.value = elemento.value.trim()

    if (elemento.value.length !== 6) {
        exibirErro('O código deve ser composto por 6 dígitos.')
        elemento.select()
        return false
    }

    return true
}

function exibirErro(strMsgErro) {
    var objDivErro = $('#divErro')
    if (strMsgErro && objDivErro.length) {
        objDivErro.html(strMsgErro)
        objDivErro.removeClass('d-none')
        objDivErro.show()
    }
}

window.SubmitCert = function() {
    $('#divInfraAvisoFundo').remove()
    infraExibirAviso(false)
    document.getElementById('frmLogin').action = document.getElementById('destino_v2_cert').value

    const linkErroCarregarCert = document.getElementById('link_erro_carregar_certificado_digital').value

    setTimeout(function() {
        infraOcultarAviso()
        exibirSubFrm(
            linkErroCarregarCert,
            800,
            600,
        )
    }, 5000)

    document.getElementById('frmLogin').submit()
}

function SubmitFormLogin(e) {
    var keycode
    if (window.event) {
        keycode = window.event.keyCode
    } else if (e) {
        keycode = e.which
    } else {
        return true
    }
    if (keycode === 13) {
        $('#sbmEntrar').click()
    }
}

window.SubmitFormSso = function(strAcao) {
    document.getElementById('hdnAcao').value = strAcao
    document.getElementById('frmLogin').submit()
}

var arrCamposEnter2FA = document.getElementsByClassName('evento-tecla-enter-2fa');
var arrCamposClick2FA;
arrCamposEnter2FA.forEach(function(elem) {
    elem.addEventListener("keypress", function(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            arrCamposClick2FA = document.getElementsByClassName('evento-clicar-2fa');
            arrCamposClick2FA.forEach(function(elem) {
                elem.click();
            });
        }
    });
});

$('#txtAcessoCodigo').keyup(function(e) {
    if (e.keyCode == 13) {
        $('#btnValidar').click()
    }
})

function abre() {
    document.getElementById('mensagemUsuario').style.display = 'block'
    document.getElementById('alertaMensagem').style.display = 'none'
}

function abreFecha() {
    if (document.getElementById('alertaMensagem').style.display == 'block') {
        document.getElementById('alertaMensagem').style.display = 'none'
    } else {
        document.getElementById('alertaMensagem').style.display = 'block'
    }
}

function fecharAbrirSubFrm() {
    document.getElementById('divInfraAvisoFundo').style.visibility = 'hidden'
    const strLinkInfoCadastroAdvogado = document.getElementById('strLinkInfoCadastroAdvogado').value
    exibirSubFrm(strLinkInfoCadastroAdvogado)
}

window.colarAcessoCodigo = function(input, event) {
    let valorPaste = (event.clipboardData || window.clipboardData).getData('text')
    valorPaste = valorPaste.replace(/[^0-9]/g, '')
    event.preventDefault()
    document.getElementById('txtAcessoCodigo').value = (valorPaste == undefined ? '' : valorPaste)
    return true
}

function showHidePwd(event) {

    if (event == 'mousedown' || event == 'touchstart') {
        var inputShowPwd = '<input type="text" id="showPwd" class="form-control form-control-sm masked" style="display:block;"/>'
        $('span.input-group').append(inputShowPwd)
        $('#showPwd').val(currentpw)
        $('#pwdSenha').hide()

    } else if ($('#showPwd').length) {
        $('#showPwd').remove()
        $('#pwdSenha').show()
    }
}

$(function() {
    var ssoFrame = document.getElementById('ssoFrame');
    if (ssoFrame) {
        var ssoFrameTesteCookies = document.getElementById('ssoFrameTesteCookies');
        ssoFrame.onload = function () {
            ssoFrameTesteCookies.src = ssoFrameTesteCookies.dataset.src;
        }
        window.addEventListener('message', function(event) {
            if (event.source === ssoFrame.contentWindow || event.source === ssoFrameTesteCookies.contentWindow) {
                if (event.data.iframeHeight !== undefined) {
                    ssoFrame.style.height = event.data.iframeHeight + 'px';
                    ssoFrame.contentWindow.postMessage({
                        certificado: $('#parametro_certificado').val(),
                        govbr: $('#parametro_govbr').val(),
                        govbr_certificado: $('#parametro_govbr_certificado').val(),
                    }, '*');
                } else if (event.data.sucesso) {
                    window.location = $('#entrar_sso').val();
                } else if (event.data.msg) {
                    tratarMsgIframe(event.data.msg, event.data.data);
                }
            }
        });
    }
})

function tratarMsgIframe(msg, data) {
    switch (msg) {
        case 'acesso_alternativo_certificado':
            window.location = $('#acesso_alternativo_certificado').val();
            break;
        case 'obter_certificado_subfrm':
            exibirSubFrm($('#obter_certificado_subfrm').val(),700,480);
            break;
        case 'problema_acesso_certificado':
            exibirSubFrm($('#problema_acesso_certificado').val(),800, 600)
            break;
        case 'fale':
            window.location = $('#fale').val();
            break;
        case 'configurar_2_fatores':
            $('#txtUsuario').val(data.username);
            $('#senha').val(data.password);
            $('#senha').attr('name', 'pwdSenha');
            Submit($('#acao_configurar_2_fatores').val());
            break;
        case 'esqueci_senha_solicitar_redefinicao':
            window.location = $('#esqueci_senha_solicitar_redefinicao').val();
            break;
        case 'cookie_not_found':
            $('#ssoFrame').remove();
            $('#divCookieTerceiros').removeClass('d-none');
            break;
        case 'erro':
        case 'reload':
            alert('Houve um erro no login. Por favor, tente novamente em alguns instantes.');
            setTimeout(function() {window.location = $('#login_sem_sso').val();}, 1000);
            break;
        case 'erro_advogado':
            alert('Não localizado advogado na OAB com o CPF informado.');
            break;
        case 'precisa_configurar_2_fatores':
            alert('É preciso configurar a autenticação em 2 fatores para entrar no sistema. ' +
            'Para isso, na tela de login, informe o usuário, senha e clique em ' +
            'Autenticação em dois fatores (abaixo do botão ENTRAR).');
            window.location.reload();
            break;
        case 'govbr':
            SubmitFormSso($('#acao_login_govbr').val())
            break;
    }
}